<template>
	<div id="myExam" v-loading="loading" :element-loading-text="loadingText"
		element-loading-background="rgba(0, 0, 0, 0)">
		<!-- 查询 -->
		<div class="search">
			<el-row :gutter="10">
				<el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="4">
					<el-input v-model="keyword" placeholder="请输入标题中包含该关键字" @input="keywordValue(keyword)"></el-input>
				</el-col>
				<el-col :xs="12" :sm="12" :md="8" :lg="12" :xl="4" class="searchStyle">
					<el-button size="small" class="searchButton" @click="searchClick">查询</el-button>
					<el-button size="small" type="warning" @click="addExamination">创建试卷信息</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- 列表 -->
		<div class="examListTable">
			<el-table :cell-style="rowClass" ref="multipleTable" :header-cell-style="headClass" :data="examData" border
				stripe>
				<template slot="empty">
					<span v-show="loading">加载中...</span>
					<span v-show="!loading">暂无数据</span>
				</template>
				<el-table-column type="index" :index="indexMethod" label="#" width="60"></el-table-column>
				<el-table-column prop="title" label="试卷名称" :show-overflow-tooltip="true">
					<template scope="scope">{{scope.row.title || '--'}}</template>
				</el-table-column>
				<el-table-column prop="minutesLimit" label="考试时长" :show-overflow-tooltip="true">
					<template scope="scope">{{scope.row.minutesLimit || '--'}}</template>
				</el-table-column>
				<el-table-column prop="fromTime" label="开始日期" :show-overflow-tooltip="true">
					<template scope="scope">{{scope.row.fromTime || '--'}}</template>
				</el-table-column>
				<el-table-column prop="toTime" label="结束日期" :show-overflow-tooltip="true">
					<template scope="scope">{{scope.row.toTime || '--'}}</template>
				</el-table-column>
				<el-table-column prop="ruleCount" label="规则数量" :show-overflow-tooltip="true">
					<template scope="scope">{{scope.row.ruleCount}}</template>
				</el-table-column>
				<el-table-column prop="assignCount" label="参考人员数量" :show-overflow-tooltip="true" width="120">
					<template scope="scope">{{scope.row.assignCount}}</template>
				</el-table-column>
				<el-table-column prop="ruleConfirmed" label="是否锁定" :show-overflow-tooltip="true" width="80"
					fixed="right">
					<template scope="scope">
						{{scope.row.ruleConfirmed? "是":"否"}}
						<!-- <el-switch @change="ruleConfirmedChange(scope.$index,scope.row)"
							v-model="scope.row.ruleConfirmed" active-text="是" inactive-text="否"></el-switch> -->
					</template>
				</el-table-column>
				<el-table-column label="操作" fixed="right" width="165">
					<template slot-scope="scope">
						<el-button type="text" style="color:#e6a23c;" @click="editClick(scope.$index,scope.row)">编辑</el-button>
						<el-button type="text" style="color:#f56c6c;" @click="removeClick(scope.$index,scope.row)">删除</el-button>
						<el-button type="text" style="color:#409EFF;" @click="ruleClick(scope.$index,scope.row)">规则</el-button>
						<el-button type="text" style="color:#f56c6c;" @click="assignClick(scope.$index,scope.row)">人员</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 查看规则弹框 -->
		<el-dialog title="查看试卷规则" :visible.sync="dialogRule" :close-on-click-modal="false" class="ruleStyle"
			:beforeClose="beforeCloseRule">
			<rule-list ref='ruleList'></rule-list>
		</el-dialog>
		<!-- 查看参考人员弹框 -->
		<el-dialog title="查看参考人员" :visible.sync="dialogAssign" :close-on-click-modal="false" class="ruleStyle"
			:beforeClose="beforeCloseAssign">
			<assign-list ref='assignList'></assign-list>
		</el-dialog>
		<!-- 创建和编辑基本信息弹框 -->
		<el-dialog :title="tip" :visible.sync="dialogExam" :close-on-click-modal="false" class="editStyle"
			:beforeClose="beforeCloseEdit">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="试卷名称" prop="title" ref="title">
					<el-input v-model="ruleForm.title" placeholder="请输入试卷名称" maxlength="500"></el-input>
				</el-form-item>
				<el-form-item label="考试时长" prop="minutesLimit" ref="minutesLimit">
					<el-input v-model="ruleForm.minutesLimit" @input="minutesLimitInput(ruleForm.minutesLimit)"
						placeholder="请输入考试时长(分钟)" maxlength="500"></el-input>
				</el-form-item>
				<el-form-item label="开始日期" prop="fromTs" ref="fromTs">
					<el-date-picker v-model="ruleForm.fromTs" type="date" value-format="timestamp"
						placeholder="请选择开始日期" :picker-options="pickerFromTs">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="结束日期" prop="toTs" ref="toTs">
					<el-date-picker v-model="ruleForm.toTs" type="date" value-format="timestamp" placeholder="请选择结束日期" :picker-options="pickerToTs">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="submitForm('ruleForm')">确定</el-button>
					<el-button size="small" @click="resetForm">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<!-- 分页 -->
		<div class="block">
			<el-pagination layout="prev, pager, next" :total="total" :current-page.sync="currentPage"
				:page-size="pageSize"></el-pagination>
		</div>
	</div>
</template>

<script>
	import RuleList from './ruleList';
	import AssignList from './assignList'
	export default {
		name: 'Exam',
		components: {
			"rule-list": RuleList,
			"assign-list": AssignList
		},
		data() {
			return {
				loading: false, //加载中
				loadingText: "",
				submitProcessing: false, //只能点击一次
				// 查询框内容
				keyword: "",
				// 考试范围数组
				scopeData: [],
				// 难易度
				difficultyData: [{
					difficulty: "简单",
					id: 1
				}, {
					difficulty: "普通",
					id: 2
				}, {
					difficulty: "困难",
					id: 3
				}],
				// 类型
				typeData: [{
					type: "单选",
					id: 1
				}, {
					type: "多选",
					id: 2
				}, {
					type: "填空",
					id: 3
				}],
				// 查询对应的key
				keywordKey: "",
				// 列表
				examData: [],
				// 分页
				currentPage: 1,
				total: 0,
				pageNum: 1,
				pageSize: 20,
				// 考试范围弹框
				dialogScope: false,
				scopeIdValue: "",
				// 编辑基本信息弹框
				dialogExam: false,
				// 当前是1创建还是2编辑
				nowStatus: 0,
				tip: "试卷创建",
				ruleForm: {
					id: '',
					title: '',
					minutesLimit: '',
					fromTs: '',
					toTs: '',
				},
				rules: {
					title: [{
						required: true,
						message: '请输入试卷名称',
						trigger: 'blur'
					}],
					minutesLimit: [{
						required: true,
						message: '请输入考试时长',
						trigger: 'blur'
					}],
					fromTs: [{
						required: true,
						message: '请选择开始日期',
						trigger: 'change'
					}],
					toTs: [{
						required: true,
						message: '请选择结束日期',
						trigger: 'change'
					}],
				},
				// 考试开始时间
				pickerFromTs: {
					disabledDate:(time)=>{
						var toTs=this.ruleForm.toTs;
						if(toTs){
							return time.getTime() >toTs;
						}
					}
				},
				// 考试结束时间
				pickerToTs: {
					disabledDate:(time)=>{
						var fromTs= this.ruleForm.fromTs;
						if(fromTs){
							return time.getTime() < fromTs;
						}
					}
				},
				// 试卷规则弹框
				dialogRule: false,
				// 参考人员
				dialogAssign: false,
			}
		},
		mounted() {
			this.getexamData();
		},
		watch: {
			// 监听点击分页
			currentPage(oldV, newV) {
				var that = this;
				that.handleCurrentChange(oldV);
			},
		},
		methods: {
			// 是否已经锁定
			ruleConfirmedChange(index, row) {
				var that = this;
				that.loading = true;
				var url = row.ruleConfirmedValue ? "/category/rule/unlock/" : "/category/rule/confirm/";
				var tip = row.ruleConfirmedValue ? "试卷规则解锁成功！" : "试卷规则锁定成功！";
				that.$axios
					.post(url + row.id)
					.then(function(res) {
						that.loading = false;
						that.$notify({
							title: '提示',
							message: tip,
							type: 'success',
							duration: 2000
						});
						row.ruleConfirmedValue = row.ruleConfirmed;
						that.handleCurrentChange(that.pageNum);
					})
					.catch(function(error) {
						that.loading = false;
						that.$notify({
							title: '失败',
							message: error,
							type: 'error',
							duration: 2000
						});
						that.handleCurrentChange(that.pageNum);
					});
			},
			// 查询时关键字不能为空
			keywordValue(val) {
				this.keyword = val.replace(/\s+/g, "");
			},
			// 考试时长输入框只能输入数字
			minutesLimitInput(val) {
				var that = this;
				that.ruleForm.minutesLimit = that.COMMON.limitCheck(val);
			},
			//表格样式
			headClass() {
				return "text-align: center;background:#D7A24A; color:#ffffff";
			},
			rowClass() {
				return "text-align: center;";
			},
			indexMethod(index) {
				return (this.pageNum - 1) * this.pageSize + index + 1;
			},
			// 查询
			searchClick() {
				var that = this;
				if (navigator.onLine == true) {
					that.keywordKey = that.keyword;
					that.pageNum = 1;
					that.currentPage = 1;
					that.getexamData();
				} else {
					that.$alert('请检查当前网络!', '提示', {
						confirmButtonText: '确定',
						type: 'error',
						callback: action => {}
					});
				}
			},
			// 创建试卷的基本信息
			addExamination() {
				var that = this;
				that.nowStatus = 1;
				that.tip = "试卷创建";
				that.dialogExam = true;
			},
			// 分页--页码的切换
			handleCurrentChange(oldV) {
				var that = this;
				that.dataList = [];
				that.pageNum = oldV;
				that.getexamData();
			},
			// 获取列表data
			getexamData() {
				var that = this;
				that.loading = true;
				that.$axios
					.post("/category/list", {
						keyword: that.keywordKey,
						pageIndex: that.pageNum - 1,
						pageSize: that.pageSize,
					})
					.then(function(res) {
						that.loading = false;
						that.examData = res.data.data;
						that.examData.forEach(item => {
							item.fromTime = that.COMMON.getDataMethods('getTime', new Date(item.fromTs));
							item.toTime = that.COMMON.getDataMethods('getTime', new Date(item.toTs));
							item.ruleConfirmedValue = item.ruleConfirmed;
						});
						that.total = res.data.totalCount;
					})
					.catch(function(error) {
						that.loading = false;
						if (error == "登录失效, 请重新登录!") {
							console.log(error)
						} else {
							that.$alert(error, '提示', {
								confirmButtonText: '确定',
								type: 'error',
								callback: action => {}
							});
						}
					});
			},
			// 删除试题
			deleteClick(index, row) {
				var self = this;
				self.$confirm('确定要删除此条试题吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (self.submitProcessing) {
						return
					}
					self.submitProcessing = true;
					self.loading = true;
					self.$axios.put("/quiz/remove/" + row.id).then(function(response) {
						self.loading = false;
						self.submitProcessing = false;
						self.$notify({
							title: '提示',
							message: '试题删除成功!',
							type: 'success',
							duration: 2000
						});
						self.addClick = false;
						self.editShow = "@test-测试#";
						self.handleCurrentChange(self.pageNum);
					}).catch(function(error) {
						self.$notify({
							title: '失败',
							message: error,
							type: 'error',
							duration: 2000
						});
						self.loading = false;
						self.submitProcessing = false;
					});
				}).catch(() => {});
			},
			// 点击编辑
			editClick(index, row) {
				var that = this;
				that.ruleForm = row;
				that.nowStatus = 2;
				that.tip = "试卷编辑";
				that.dialogExam = true;
			},
			removeClick(index, row){
				console.log(row);
				var that = this;
				that.$confirm('若执行该操作，则已下发的用户试题、试卷规则也将一并删除，确定要删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					that.$axios.put("/category/remove/"+row.id)
						.then(res => {
							that.$notify({
								title: '提示',
								message: '删除成功!',
								type: 'success',
								duration: 2000
							});
							that.handleCurrentChange(that.pageNum);
						})
						.catch(err => {
							that.$notify({
								title: '失败',
								message: err,
								type: 'error',
								duration: 2000
							});
						})
				}).catch(() => {});
			},
			// 确定创建、编辑
			submitForm(formName) {
				var that = this;
				that.loading = true;
				if (that.submitProcessing) {
					return;
				}
				that.submitProcessing = true;
				that.$refs[formName].validate((valid) => {
					if (valid) {
						var url = that.nowStatus == 2 ? "/category/edit" : "/category/add";
						var post = {
							id: that.ruleForm.id,
							title: that.ruleForm.title,
							minutesLimit: that.ruleForm.minutesLimit,
							fromTs: that.ruleForm.fromTs,
							toTs: that.ruleForm.toTs + 24 * 60 * 60 * 1000 - 1000,
						};
						that.$axios.post(url, post)
							.then(res => {
								that.loading = false;
								that.submitProcessing = false;
								that.$notify({
									title: '提示',
									message: that.tip + '成功!',
									type: 'success',
									duration: 2000
								});
								that.beforeCloseEdit();
								that.handleCurrentChange(that.pageNum);
							})
							.catch(err => {
								that.loading = false;
								that.submitProcessing = false;
								that.$notify({
									title: '失败',
									message: err,
									type: 'error',
									duration: 2000
								});
							})
					} else {
						that.loading = false;
						that.submitProcessing = false;
						return false;
					}
				});
			},
			// 关闭之前编辑
			beforeCloseEdit() {
				var that = this;
				that.ruleForm = {
					id: '',
					title: '',
					minutesLimit: '',
					fromTs: '',
					toTs: '',
				};
				setTimeout(function() {
					that.$refs.ruleForm.clearValidate();
					that.dialogExam = false;
				}, 50)
			},
			// 取消编辑基本信息
			resetForm() {
				this.beforeCloseEdit();
			},
			// 试卷规则
			ruleClick(index, row) {
				var that = this;
				that.dialogRule = true;
				setTimeout(function() {
					that.$refs.ruleList.getRulesData(row.id);
				});
			},
			beforeCloseRule() {
				var that = this;
				that.handleCurrentChange(that.pageNum);
				that.dialogRule = false;
				that.$refs.ruleList.initData();
			},
			// 参考人员
			assignClick(index, row) {
				var that = this;
				that.dialogAssign = true;
				setTimeout(function() {
					that.$refs.assignList.getAssignData(row.id);
				});
			},
			beforeCloseAssign() {
				var that = this;
				that.handleCurrentChange(that.pageNum);
				that.dialogAssign = false;
				that.$refs.assignList.initData();
			}
		}
	}
</script>

<style lang='scss' scoped>
	#myExam {
		background: #FFFFFF;
		padding: 15px;

		/* 查询 */
		.search {
			.searchButton {
				background: #000000;
				color: #FFFFFF;
			}

			.el-col {
				margin: 5px 0;
			}

			.el-select {
				width: 100%;
			}

			.el-button {
				margin-top: 5px;
			}
		}

		/* 列表 */
		.examListTable {
			margin: 10px auto;
		}

		/* 分页 */
		.block {
			background: transparent;
		}
	}
</style>
